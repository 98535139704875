import React, { useRef } from "react";
import { useInView } from "framer-motion";
import { DataFAQ } from "../utils/dummyData";
import { ButtonSimple } from "../components/Button";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import spider from "../components/lotties/spider.json";

const animation = {
  anim1: (isInView) => {
    let style = {
      transform: isInView ? "none" : "translateX(250px)",
      WebkitTransform: isInView ? "none" : "translateX(250px)",
      MozTransform: isInView ? "none" : "translateX(250px)",
      msTransform: isInView ? "none" : "translateX(250px)",
      OTransform: isInView ? "none" : "translateX(250px)",
      opacity: isInView ? 1 : 0,
      transition: `all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.4s`,
    };
    return style;
  },
  anim2: (isInView, delay) => {
    let style = {
      transform: isInView ? "none" : "translateX(-250px)",
      WebkitTransform: isInView ? "none" : "translateX(-250px)",
      MozTransform: isInView ? "none" : "translateX(-250px)",
      msTransform: isInView ? "none" : "translateX(-250px)",
      OTransform: isInView ? "none" : "translateX(-250px)",
      opacity: isInView ? 1 : 0,
      transition: `all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) ${delay + 0.4}s`,
    };
    return style;
  },
  anim3: (isInView, delay) => {
    let style = {
      transform: isInView ? "none" : "translateX(250px)",
      WebkitTransform: isInView ? "none" : "translateX(250px)",
      MozTransform: isInView ? "none" : "translateX(250px)",
      msTransform: isInView ? "none" : "translateX(250px)",
      OTransform: isInView ? "none" : "translateX(250px)",
      opacity: isInView ? 1 : 0,
      transition: `all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) ${delay + 1.3}s`,
    };
    return style;
  },
};

function FAQ() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const optionsSpider = {
    loop: true,
    autoplay: true,
    animationData: spider,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Ramadhan Theme
  // const { rotateLeft, rotateRight } = useContext(motionContext);
  // const [showAnimation, setShowAnimation] = useState(false);
  // const handleAnimation = (isInView) => {
  //   let style = {
  //     opacity: isInView && showAnimation ? 1 : 0,
  //     transition: `all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 1s`,
  //   };
  //   return style;
  // };
  // useEffect(() => {
  //   if (!showAnimation && isInView) {
  //     setShowAnimation(true);
  //   }
  // }, [showAnimation, isInView]);

  return (
    <div className="relative flex flex-col space-y-10 sm:space-y-12 lg:space-y-16 pt-8 pb-8 sm:py-[5vh] mb-2 sm:mb-8 lg:mb-6 bg-white">
      {/* {rotateLeft !== 0 && rotateRight !== 0 && showLampion && (
        <div className="w-full absolute top-0">
          <div
            style={animationLamp(isInView)}
            className="absolute w-[23%] sm:w-2/12 xl:w-[20%] left-0 top-0"
          >
            <LampionRamadhanLeft />
          </div>
          <div
            style={animationLamp(isInView)}
            className="absolute w-[23%] sm:w-2/12 xl:w-[20%] right-0 top-0"
          >
            <LampionRamadhanRight />
          </div>
        </div>
      )} */}

      <div className="w-full absolute top-0">
        <div className="w-4/12 lg:w-80 h-auto flex absolute right-0 top-0">
          <img
            className="w-full h-auto object-cover"
            src={`/images/theme/halloween/spider-web.svg`}
            alt="Spider-Web"
          />
        </div>

        <div className="w-4/12 lg:w-80 h-auto flex absolute left-0 top-0">
          <img
            className="w-full h-auto object-cover"
            src={`/images/theme/halloween/spider-web.svg`}
            alt="Spider-Web"
            style={{ transform: "rotateY(180deg)" }}
          />
        </div>

        <div className="absolute w-14 lg:w-32 h-auto left-4 top-0 cursor-default">
          <Lottie
            options={optionsSpider}
            height={"auto"}
            width={"100%"}
            speed={1}
            isClickToPauseDisabled={true}
          />
        </div>

        <div className="absolute w-14 lg:w-32 h-auto right-4 top-0">
          <Lottie
            options={optionsSpider}
            height={"auto"}
            width={"100%"}
            speed={1}
            isClickToPauseDisabled={true}
          />
        </div>
      </div>

      <div
        ref={ref}
        className="w-full sm:w-5/12 flex flex-col mx-auto items-center text-center"
        style={animation.anim1(isInView)}
      >
        <h5 className="text-graylight text-xl sm:text-2.2xl lg:text-3xl xl:text-3.1xl font-bold font-nunito-sans">
          FAQ
        </h5>
      </div>

      <div className="w-10/12 sm:w-9/12 xl:w-8/12 flex flex-wrap items-start mx-auto font-serif">
        <div className="w-6/12 hidden sm:flex flex-col space-y-5 pr-10 xl:pr-14 pb-5">
          {DataFAQ.slice(0, 3).map((item, idx) => {
            return (
              <div
                key={item.id}
                className="w-full flex flex-col space-y-3 xl:space-y-5 pr-5 pb-10 font-nunito-sans text-graylight"
                style={animation.anim2(isInView, 0.3 * (idx + 1))}
              >
                <h5 className="text-1xl xl:text-2.1xl font-bold">
                  {item.question}
                </h5>
                <p className="text-base xl:text-lg text-justify leading-snug whitespace-pre-wrap">
                  {item.answer}
                </p>
              </div>
            );
          })}
        </div>

        <div className="w-6/12 hidden sm:flex flex-col space-y-5 pl-10 xl:pl-14 pb-5">
          {DataFAQ.slice(3, 5).map((item, idx) => {
            const lastItem = DataFAQ.slice(3, 5).length - 1;

            return (
              <div
                key={item.id}
                className="w-full flex flex-col space-y-3 xl:space-y-5 pr-5 pb-10 font-nunito-sans text-graylight"
                style={animation.anim3(isInView, 0.3 * (idx + 1))}
              >
                <h5 className="text-1xl xl:text-2.1xl font-bold">
                  {item.question}
                </h5>
                <p className="text-base xl:text-lg text-justify leading-snug whitespace-pre-wrap">
                  {item.answer}
                </p>
                {lastItem === idx ? (
                  <div className="flex justify-center items-center pt-2 xl:pt-0">
                    <ButtonSimple>
                      <Link to="/faq">SELENGKAPNYA</Link>
                    </ButtonSimple>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            );
          })}
        </div>

        <div className="w-full flex sm:hidden flex-col space-y-6">
          {DataFAQ.slice(0, 4).map((item, idx) => {
            return (
              <div
                key={item.id}
                className="w-full flex flex-col space-y-2 font-nunito-sans text-graylight"
                style={animation.anim2(isInView, 0.3 * (idx + 1))}
              >
                <h5 className="text-base font-bold">{item.question}</h5>
                <p className="text-sm text-justify leading-snug whitespace-pre-wrap">
                  {item.answer}
                </p>
              </div>
            );
          })}
          <div className="flex justify-center items-center">
            <ButtonSimple>
              <Link to="/faq">SELENGKAPNYA</Link>
            </ButtonSimple>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
