import React from "react";
import "./PromoModal.scss";

import { motion } from "framer-motion";
import Close from "../svg/Close";
import { Link } from "react-router-dom";

const variants = {
  hidden: {
    opacity: 0,
    zIndex: -1,
    transition: { ease: "backOut", duration: 0.25 },
  },
  show: {
    opacity: 1,
    zIndex: 1000,
    transition: { ease: "backOut", duration: 0.25 },
  },
};

const variants2 = {
  hidden: {
    scale: 0.6,
    transition: { ease: "easeInOut", duration: 0.25 },
  },
  show: {
    scale: 1,
    transition: { ease: "easeInOut", duration: 0.25 },
  },
};

function PromoModal({ show, setShow, toggleModal }) {
  // State for Chinese New Year Theme Animation
  // const [isFill, setFill] = useState(true);
  // const [speed, setSpeed] = useState({
  //   lottie1: 1,
  //   lottie2: 1,
  //   lottie3: 1,
  // });

  // const optionsDiscount = {
  //   loop: false,
  //   animationData: show ? discount : "",
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice",
  //   },
  // };

  // const optionsBaloonFreeOngkir = {
  //   loop: false,
  //   animationData: show ? baloonFreeOngkir : "",
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice",
  //   },
  // };

  // const optionsBaloonXtraCashback = {
  //   loop: false,
  //   animationData: show ? baloonXtraCashback : "",
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice",
  //   },
  // };

  // useEffect for Chinese New Year Theme Animation
  // useEffect(() => {
  //   if (isFill) {
  //     setFill(false);
  //     setSpeed({
  //       lottie1: 0.85,
  //       lottie2: 0.8,
  //       lottie3: 0.85,
  //     });
  //   }
  // }, [isFill]);

  return (
    <motion.div
      className="promoModal"
      initial={{ opacity: 0, zIndex: -1 }}
      variants={variants}
      animate={show ? "show" : "hidden"}
      //   onClick={toggleModal}
    >
      {/* Independence Day Theme */}
      {/* <motion.div
        className="promoModal-card bg-white max-w-full w-[94%] sm:w-[75%] h-auto relative flex flex-col p-8 rounded-[10px] lg:rounded-[35px] overflow-auto"
        initial={{ scale: 0.5 }}
        variants={variants2}
        animate={show ? "show" : "hidden"}
      >
        <button
          onClick={toggleModal}
          className="w-8 h-8 2xl:w-9 2xl:h-9 absolute top-4 right-4 sm:top-5 sm:right-5 cursor-pointer rounded-full z-20"
        >
          <Close color="#D31C1C" className="w-full h-auto" />
        </button>

        <img
          className="absolute w-11/12 h-auto absolute-center object-cover z-10"
          src={`/images/theme/independence-day/map.png`}
          alt="Indonesian Map"
        />

        <img
          className="absolute w-32 lg:w-[28%] 5xl:w-[24%] h-auto bottom-0 left-0"
          src={`/images/theme/independence-day/indonesian-flag-left.svg`}
          alt="Indonesian Flag"
        />
        <img
          className="absolute w-32 lg:w-[28%] 5xl:w-[24%] h-auto bottom-0 right-0"
          src={`/images/theme/independence-day/indonesian-flag-right.svg`}
          alt="Indonesian Flag"
        />

        <div className="relative w-11/12 mx-auto flex flex-col space-y-6 justify-center items-center z-20">
          <h2 className="text-xl lg:3xl xl:text-3.2xl text-[#D31C1C] font-black font-sans text-center">
            DIRGAHAYU INDONESIA 78
            <br />
            <span className="font-normal">PROMO UP TO</span>
          </h2>
          <img
            className="xl:w-48 h-auto"
            src={`/images/theme/independence-day/78.png`}
            alt="Promo 78%"
          />
          <div className="w-full flex flex-col space-y-4 items-center font-nunito-sans">
            <Link
              to={"/link-promo/shopee"}
              className="relative flex items-center w-80 p-2 space-x-5 text-sm sm:text-lg font-bold bg-white lg:bg-transparent hover:bg-white rounded-full z-[10] shadow-xl border-[3px] border-[#D31C1C] transition-all duration-150"
            >
              <div className="flex p-2 justify-center items-center h-9 w-9 sm:h-11 sm:w-11 rounded-full bg-[#F65421]">
                <img
                  src="/images/icon-marketplace/shopee.svg"
                  alt="Store"
                  className="w-auto h-full object-contain"
                />
              </div>

              <p className="text-[#D31C1C]">DHC Store di Shopee</p>
            </Link>

            <Link
              to={"/link-promo/tokopedia"}
              className="relative flex items-center w-80 p-2 space-x-5 text-sm sm:text-lg font-bold bg-white lg:bg-transparent hover:bg-white rounded-full z-[10] shadow-xl border-[3px] border-[#D31C1C] transition-all duration-150"
            >
              <div className="flex p-2 justify-center items-center h-9 w-9 sm:h-11 sm:w-11 rounded-full bg-[#4D984C]">
                <img
                  src="/images/icon-marketplace/tokopedia.svg"
                  alt="Store"
                  className="w-auto h-full object-contain"
                />
              </div>

              <p className="text-[#D31C1C]">DHC Store di Tokopedia</p>
            </Link>

            <Link
              to={"/link-promo/lazada"}
              className="relative flex items-center w-80 p-2 space-x-5 text-sm sm:text-lg font-bold bg-white lg:bg-transparent hover:bg-white rounded-full z-[10] shadow-xl border-[3px] border-[#D31C1C] transition-all duration-150"
            >
              <div className="flex p-2 justify-center items-center h-9 w-9 sm:h-11 sm:w-11 rounded-full bg-[#010084]">
                <img
                  src="/images/icon-marketplace/lazada.svg"
                  alt="Store"
                  className="w-auto h-full object-contain"
                />
              </div>

              <p className="text-[#D31C1C]">DHC Store di Lazada</p>
            </Link>
          </div>
        </div>
      </motion.div> */}

      {/* Halloween Theme */}
      <motion.div
        className="promoModal-card bg-halloween bg-white max-w-full w-[94%] sm:w-[68%] h-auto relative flex flex-col p-8 rounded-[10px] lg:rounded-[35px] overflow-auto"
        initial={{ scale: 0.5 }}
        variants={variants2}
        animate={show ? "show" : "hidden"}
      >
        <div>
          <button
            onClick={toggleModal}
            className="w-8 h-8 2xl:w-9 2xl:h-9 absolute top-4 right-4 sm:top-5 sm:right-5 cursor-pointer rounded-full z-20"
          >
            <Close color="#FFEBB6" className="w-full h-auto" />
          </button>

          <div className="relative w-11/12 mx-auto flex flex-col space-y-6 justify-center items-center z-20">
            <div className="w-full lg:w-7/12 h-auto flex justify-center">
              <img
                className="w-8/12 h-auto object-cover"
                src="/images/theme/halloween/title.svg"
                alt="Halloween Title"
              />
            </div>
            <div className="w-full lg:w-6/12 h-auto flex">
              <img
                className="w-full h-auto object-cover"
                src={`/images/theme/halloween/subtitle.svg`}
                alt="Halloween Subtitle"
              />
            </div>
            <div className="w-full flex flex-col space-y-3 items-center font-nunito-sans">
              <Link
                to={"/link-promo/tokopedia"}
                className="relative flex items-center w-80 p-2 space-x-5 text-sm sm:text-lg font-bold bg-[#1A142D] hover:bg-[#433360] rounded-full z-[10] shadow-xl border-[3px] border-dark transition-all duration-150"
              >
                <div className="shrink-0 flex p-2 justify-center items-center h-9 w-9 sm:h-10 sm:w-10 rounded-full bg-[#4D984C]">
                  <img
                    src="/images/icon-marketplace/tokopedia.svg"
                    alt="Store"
                    className="w-auto h-full object-contain"
                  />
                </div>

                <p className="text-[#FFEBB6]">DHC Store di Tokopedia</p>
              </Link>
            </div>
          </div>

          <div className="w-full flex justify-between mt-5 lg:-mt-10">
            <div className="w-[45%] lg:w-[30%] h-auto flex">
              <img
                className="w-full h-auto object-cover"
                src={`/images/theme/halloween/ghost-2.png`}
                alt="Ghost 1"
              />
            </div>

            <div className="w-[45%] lg:w-[30%] h-auto flex">
              <img
                className="w-full h-auto object-contain"
                src={`/images/theme/halloween/ghost-1.png`}
                alt="Ghost 1"
              />
            </div>
          </div>
        </div>
      </motion.div>

      {/* Christmas Theme */}
      {/* <motion.div
        className="promoModal-card bg-white max-w-full w-[94%] sm:w-[65%] h-auto relative flex flex-col p-8 rounded-[10px] lg:rounded-[35px] overflow-auto"
        initial={{ scale: 0.5 }}
        variants={variants2}
        animate={show ? "show" : "hidden"}
        // halloween theme style
        style={{
          background: `url('/images/theme/christmas/bg-promo-christmas.png')`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div>
          <button
            onClick={toggleModal}
            className="w-8 h-8 2xl:w-9 2xl:h-9 absolute top-4 right-4 sm:top-5 sm:right-5 cursor-pointer rounded-full z-20"
          >
            <Close color="#ffffff" className="w-full h-auto" />
          </button>

          <div className="relative w-full lg:w-11/12 mx-auto flex flex-col justify-center items-center z-20">
            <div className="w-10/12 lg:w-6/12 h-auto flex">
              <img
                className="w-full h-auto object-cover"
                src="/images/theme/christmas/title-christmas.png"
                alt="Christmas Title"
              />
            </div>
            <div className="w-full lg:w-10/12 h-auto flex mb-10 mt-2 lg:-mt-2">
              <img
                className="w-full h-auto object-cover"
                src={`/images/theme/christmas/subtitle-christmas.png`}
                alt="Christmas Subtitle"
              />
            </div>
            <div className="w-full flex flex-col space-y-3 items-center font-nunito-sans">
              <Link
                to={"/link-promo/shopee"}
                className="relative flex items-center w-80 p-2 space-x-5 text-sm sm:text-lg font-bold bg-[#9E7778] hover:bg-[#8b6768] rounded-full z-[10] shadow-xl border-[3px] border-white transition-all duration-150"
              >
                <div className="flex p-2 justify-center items-center h-9 w-9 sm:h-10 sm:w-10 rounded-full bg-[#F65421]">
                  <img
                    src="/images/icon-marketplace/shopee.svg"
                    alt="Store"
                    className="w-auto h-full object-contain"
                  />
                </div>

                <p className="text-white">DHC Store di Shopee</p>
              </Link>

              <Link
                to={"/link-promo/tokopedia"}
                className="relative flex items-center w-80 p-2 space-x-5 text-sm sm:text-lg font-bold bg-[#9E7778] hover:bg-[#8b6768] rounded-full z-[10] shadow-xl border-[3px] border-white transition-all duration-150"
              >
                <div className="shrink-0 flex p-2 justify-center items-center h-9 w-9 sm:h-10 sm:w-10 rounded-full bg-[#4D984C]">
                  <img
                    src="/images/icon-marketplace/tokopedia.svg"
                    alt="Store"
                    className="w-auto h-full object-contain"
                  />
                </div>

                <p className="text-white">DHC Store di Tokopedia</p>
              </Link>

              <Link
                to={"/link-promo/aladin"}
                className="relative flex items-center w-80 p-2 space-x-5 text-sm sm:text-lg font-bold bg-[#9E7778] hover:bg-[#8b6768] rounded-full z-[10] shadow-xl border-[3px] border-white transition-all duration-150"
              >
                <div className="shrink-0 flex p-2 justify-center items-center h-9 w-9 sm:h-10 sm:w-10 rounded-full bg-[#DEE5D5]">
                  <img
                    src="/images/icon-marketplace/aladin.svg"
                    alt="Store"
                    className="w-auto h-full object-contain"
                  />
                </div>

                <p className="text-white">DHC Store di Aladin</p>
              </Link>
            </div>
          </div>
        </div>
      </motion.div> */}

      {/* Chinese New Year Theme */}
      {/* <motion.div
        className="promoModal-card bg-chinese-ny bg-white max-w-full w-[94%] sm:w-[70%] lg:w-[76%] h-[76vh] lg:h-[90vh] relative flex flex-col py-6 px-1 sm:p-8 rounded-[10px] lg:rounded-[35px] overflow-auto"
        initial={{ scale: 0.5 }}
        variants={variants2}
        animate={show ? "show" : "hidden"}
      >
        <div>
          <button
            onClick={toggleModal}
            className="w-8 h-8 2xl:w-9 2xl:h-9 absolute top-4 right-4 sm:top-5 sm:right-5 rounded-full z-20"
          >
            <Close color="#9C1C11" className="w-full h-auto" />
          </button>

          <div className="block lg:hidden w-[140px] h-auto absolute bottom-6 right-0 z-50 overflow-hidden">
            <motion.button
              onClick={() => {
                setScroll(!scroll);
              }}
              className="w-full relative block lg:hidden"
              initial={{
                x: 5,
              }}
              animate={
                scroll
                  ? {
                      x: [5, 0, 118],
                    }
                  : {
                      x: [118, 123, 5],
                    }
              }
              transition={{
                duration: 0.6,
                ease: "easeOut",
              }}
            >
              <img
                src={"/images/theme/chinese-new-year/scroll.svg"}
                alt="Scroll"
                className="relative w-full h-auto cover z-0"
              />
              <div
                onClick={() => {
                  setScroll(!scroll);
                }}
                className="absolute absolute-y-center right-3 flex flex-col z-10 text-white text-[11px] font-nunito-sans font-bold"
              >
                <span>Periode</span>
                <span>5 - 11 Februari 2024</span>
              </div>
            </motion.button>
          </div>

          <div className="hidden lg:block w-[154px] lg:w-40 xl:w-48 h-auto absolute absolute-y-center right-0 z-50 overflow-hidden">
            <motion.button
              onClick={() => {
                setScroll(!scroll);
              }}
              className="w-full relative hidden lg:block xl:hidden"
              initial={{
                x: 5,
              }}
              animate={
                scroll
                  ? {
                      x: [5, 0, 132],
                    }
                  : {
                      x: [132, 137, 5],
                    }
              }
              transition={{
                duration: 0.6,
                ease: "easeOut",
              }}
            >
              <img
                src={"/images/theme/chinese-new-year/scroll.svg"}
                alt="Scroll"
                className="relative w-full h-auto cover z-0"
              />
              <div
                onClick={() => {
                  setScroll(!scroll);
                }}
                className="absolute absolute-y-center right-4 pb-1 flex flex-col z-10 text-white lg:text-xxs xl:text-sm font-nunito-sans font-semibold"
              >
                <span>Periode</span>
                <span>5 - 11 Februari 2024</span>
              </div>
            </motion.button>

            <motion.button
              onClick={() => {
                setScroll(!scroll);
              }}
              className="w-full relative hidden xl:block"
              initial={{
                x: 6,
              }}
              animate={
                scroll
                  ? {
                      x: [6, 0, 158],
                    }
                  : {
                      x: [158, 164, 6],
                    }
              }
              transition={{
                duration: 0.6,
                ease: "easeOut",
              }}
            >
              <img
                src={"/images/theme/chinese-new-year/scroll.svg"}
                alt="Scroll"
                className="relative w-full h-auto cover z-0"
              />
              <div
                onClick={() => {
                  setScroll(!scroll);
                }}
                className="absolute absolute-y-center right-4 pb-1 flex flex-col z-10 text-white text-sm font-nunito-sans font-semibold"
              >
                <span>Periode</span>
                <span>5 - 11 Februari 2024</span>
              </div>
            </motion.button>
          </div>

          <div className="relative w-full lg:w-11/12 mx-auto flex flex-col justify-center items-center z-20">
            <div className="w-full h-auto flex items-center justify-center mb-5 lg:mb-6">
              <div
                className="w-full flex flex-col lg:block justify-center items-center lg:space-x-0 capitalize text-5xl lg:text-6xl xl:text-7.1xl text-center"
                style={{ fontFamily: "'Ceviche One', system-ui" }}
              >
                <span className="text-[#642516]">CHINESE </span>
                <span className="text-[#9C1C11]">NEW YEAR</span>
              </div>
            </div>
            <div className="w-full h-auto flex lg:hidden flex-col lg:flex-row items-center justify-center space-x-2 lg:space-x-4 mb-5 lg:mb-10 font-nunito-sans">
              <div className="w-auto flex flex-col -space-y-1 lg:space-y-0 items-center justify-center rounded-xl border-2 border-white bg-[#642516] text-white text-lg lg:text-1xl xl:text-2.2xl font-bold p-2 lg:p-3">
                <span>Diskon</span>
                <span>Folic Acid & Vitamin C</span>
              </div>
              <div className="w-auto flex flex-col space-y-0 lg:space-y-1 items-center lg:items-start text-[#CB352B]">
                <span className="text-1xl lg:text-2.2xl xl:text-4xl font-bold font-sans">
                  up to
                </span>
                <span className="w-[124px] lg:w-36 h-auto">
                  <img
                    src="/images/theme/chinese-new-year/13.svg"
                    alt="13%"
                    className="w-full h-auto"
                  />
                </span>
              </div>
            </div>
            <div className="w-full h-auto hidden lg:flex flex-col lg:flex-row items-center justify-center space-x-2 lg:space-x-4 mb-5 lg:mb-8 font-nunito-sans">
              <div className="w-[54%] flex flex-col space-y-0 lg:space-y-1 items-center lg:items-start text-[#CB352B]">
                <img
                  src="/images/theme/chinese-new-year/title-promo.png"
                  alt="Promo"
                  className="w-full h-auto"
                />
              </div>
            </div>
            <div className="w-full flex flex-col space-y-3 items-center font-nunito-sans mb-10">
              <Link
                to={"/link-promo/shopee"}
                className="relative flex items-center w-72 sm:w-80 p-2 space-x-5 text-sm sm:text-lg font-bold bg-white hover:bg-slate-100 rounded-full z-[10] shadow-xl border-[3px] border-[#CB352B] transition-all duration-150"
              >
                <div className="flex p-2 justify-center items-center h-9 w-9 sm:h-10 sm:w-10 rounded-full bg-[#F65421]">
                  <img
                    src="/images/icon-marketplace/shopee.svg"
                    alt="Store"
                    className="w-auto h-full object-contain"
                  />
                </div>

                <p className="text-[#642516]">DHC Store di Shopee</p>
              </Link>
            </div>
          </div>
        </div>
      </motion.div> */}

      {/* Valentine Theme */}
      {/* <motion.div
        className="promoModal-card bg-valentine bg-white max-w-full w-[94%] sm:w-[58%] lg:w-[60%] h-[73vh] lg:h-[90vh] relative flex flex-col py-6 px-1 sm:p-8 rounded-[10px] lg:rounded-[35px] overflow-auto"
        initial={{ scale: 0.5 }}
        variants={variants2}
        animate={show ? "show" : "hidden"}
      >
        <div>
          <button
            onClick={toggleModal}
            className="w-8 h-8 2xl:w-9 2xl:h-9 absolute top-4 right-4 sm:top-5 sm:right-5 rounded-full z-20"
          >
            <Close color="#995AA8" className="w-full h-auto" />
          </button>

          <div className="w-20 lg:w-40 flex absolute bottom-[14vh] left-4 ">
            <motion.img
              src="/images/theme/valentine/baloon-extra-cashback.svg"
              alt="Cashback"
              className="w-full h-auto"
              initial={{ y: 0 }}
              animate={{ y: [0, -18, 0] }}
              transition={{
                duration: 4.2,
                repeat: "Infinity",
                delay: 0.5,
              }}
            />
          </div>

          <div className="w-20 lg:w-40 flex absolute bottom-[14vh] right-4">
            <motion.img
              src="/images/theme/valentine/baloon-free-shipping.svg"
              alt="Cashback"
              className="w-full h-auto"
              initial={{ y: 0 }}
              animate={{ y: [0, -15, 0] }}
              transition={{
                duration: 4,
                repeat: "Infinity",
              }}
            />
          </div>

          <div className="relative w-full lg:w-11/12 mx-auto flex flex-col justify-center items-center z-20">
            <div className="w-9/12 lg:w-5/12 h-auto flex items-center justify-center mb-6 mt-2">
              <img
                src="/images/theme/valentine/title-promo.svg"
                alt="Promo"
                className="w-full h-auto"
              />
            </div>

            <div className="w-full flex flex-col space-y-3 items-center font-nunito-sans">
              <Link
                to={"/link-promo/shopee"}
                className="relative flex items-center w-72 sm:w-80 p-2 space-x-5 text-sm sm:text-lg font-bold bg-white hover:bg-slate-100 rounded-full z-[10] shadow-xl border-[3px] border-[#995AA8] transition-all duration-150"
              >
                <div className="flex p-2 justify-center items-center h-9 w-9 sm:h-10 sm:w-10 rounded-full bg-[#F65421]">
                  <img
                    src="/images/icon-marketplace/shopee.svg"
                    alt="Store"
                    className="w-auto h-full object-contain"
                  />
                </div>

                <p className="text-[#995AA8]">DHC Store di Shopee</p>
              </Link>
            </div>
          </div>
        </div>
      </motion.div> */}

      {/* Ramadhan Day Theme */}
      {/* <motion.div
        className="promoModal-card bg-ramadhan bg-white max-w-full w-[94%] sm:w-[58%] lg:w-[60%] h-[70vh] lg:h-[92vh] relative flex flex-col py-6 px-1 sm:p-8 rounded-[10px] lg:rounded-[35px] overflow-auto"
        initial={{ scale: 0.5 }}
        variants={variants2}
        animate={show ? "show" : "hidden"}
      >
        <div>
          <button
            onClick={toggleModal}
            className="w-8 h-8 2xl:w-9 2xl:h-9 absolute top-4 right-4 sm:top-5 sm:right-5 rounded-full z-30"
          >
            <Close color="#F8E0C1" className="w-full h-auto" />
          </button>

          <div className="w-10/12 lg:w-9/12 flex absolute -bottom-0 absolute-x-center overflow-hidden">
            <motion.img
              src="/images/theme/ramadhan/products.png"
              alt="Cashback"
              className="w-full h-auto"
              initial={{ y: 500 }}
              animate={{ y: 0 }}
              transition={{
                duration: 0.8,
                delay: 0.2,
                ease: "easeOut",
              }}
            />
          </div>

          <div className="relative w-full lg:w-11/12 mx-auto flex flex-col justify-center items-center z-20 -mt-3">
            <div className="w-10/12 lg:w-[52%] h-auto flex items-center justify-center mb-7 lg:mb-5 mt-2">
              <img
                src="/images/theme/ramadhan/title-promo.svg"
                alt="Promo"
                className="w-full h-auto"
              />
            </div>

            <div className="w-10/12 lg:w-7/12 h-auto flex items-center justify-center mb-7 lg:mb-5 mt-2">
              <img
                src="/images/theme/ramadhan/subtitle-promo.svg"
                alt="Promo"
                className="w-full h-auto"
              />
            </div>

            <div className="w-full flex flex-col space-y-3 items-center font-nunito-sans">
              <a
                href={"https://dhcsupplement.co.id/link"}
                target="__blank"
                referrerPolicy="no-referrer"
                className="relative flex justify-center items-center w-72 sm:w-80 p-2 space-x-5 text-sm sm:text-lg font-bold bg-white hover:bg-slate-100 rounded-full z-[10] shadow-xl border-[3px] border-[#061B38] transition-all duration-150"
              >
                <p className="text-[#061B38]">Klik Disini</p>
              </a>
            </div>
          </div>
        </div>
      </motion.div> */}

      {/* Kartini Day Theme */}
      {/* <motion.div
        className="promoModal-card bg-kartini bg-white max-w-full w-[94%] sm:w-[58%] lg:w-[60%] h-[63vh] lg:h-[90vh] relative flex flex-col pb-6 rounded-[10px] lg:rounded-[30px] overflow-auto"
        initial={{ scale: 0.5 }}
        variants={variants2}
        animate={show ? "show" : "hidden"}
      >
        <div>
          <button
            onClick={toggleModal}
            className="w-8 h-8 2xl:w-9 2xl:h-9 absolute top-2 right-2 lg:top-3 lg:right-3 rounded-full z-30"
          >
            <Close color="#000000" className="w-full h-auto" />
          </button>

          <div className="w-auto h-[42%] lg:w-[30%] lg:h-auto absolute bottom-0 left-0 z-20">
            <img
              src="/images/theme/kartini/kartini-char.svg"
              alt="Kartini"
              className="w-auto h-full lg:w-full lg:h-auto"
            />
          </div>

          <div className="relative w-full mx-auto flex flex-col justify-center items-center z-10">
            <div className="w-full h-auto flex items-center justify-center">
              <img
                src="/images/theme/kartini/title-promo.png"
                alt="Promo"
                className="hidden lg:block w-full h-auto"
              />
              <img
                src="/images/theme/kartini/title-promo-sm.png"
                alt="Promo"
                className="block lg:hidden w-full h-auto"
              />
            </div>
          </div>

          <div className="relative w-full flex justify-center z-30 font-nunito-sans p-0 lg:pt-7">
            <a
              href={"https://dhcsupplement.co.id/link"}
              target="__blank"
              referrerPolicy="no-referrer"
              className="flex justify-center items-center w-40 sm:w-80 p-2 space-x-5 text-sm sm:text-lg font-bold bg-white hover:bg-slate-100 rounded-full shadow-xl border-[3px] border-[#061B38] transition-all duration-150"
            >
              <p className="text-[#061B38]">Klik Disini</p>
            </a>
          </div>
        </div>
      </motion.div> */}

      {/* Independence Day Theme V2 */}
      {/* <motion.div
        className="promoModal-card bg-independence-day-v2 bg-white max-w-full w-[94%] sm:w-[75%] h-auto relative flex flex-col px-8 pt-8 rounded-[10px] lg:rounded-[35px] overflow-auto"
        initial={{ scale: 0.5 }}
        variants={variants2}
        animate={show ? "show" : "hidden"}
      >
        <button
          onClick={toggleModal}
          className="w-8 h-8 2xl:w-9 2xl:h-9 absolute top-4 right-4 sm:top-5 sm:right-5 cursor-pointer rounded-full z-20"
        >
          <Close color="#D31C1C" className="w-full h-auto" />
        </button>

        <img
          className="flex lg:hidden w-11/12 h-auto absolute absolute-x-center bottom-16"
          src={`/images/theme/independence-day-v2/confeti-promo-sm.svg`}
          alt="Confeti"
        />

        <div className="flex lg:hidden absolute bottom-40 left-2">
          <Lottie
            options={optionsDiscount}
            height={"auto"}
            width={66}
            speed={speed?.lottie1}
          />
        </div>

        <img
          className="flex lg:hidden w-5 h-auto absolute right-0 bottom-24"
          src={`/images/theme/independence-day-v2/date-promo.svg`}
          alt="Date"
        />

        <div className="relative w-11/12 mx-auto flex flex-col space-y-6 justify-center items-center z-20">
          <img
            className="w-full lg:w-5/12 h-auto"
            src={`/images/theme/independence-day-v2/title-promo.png`}
            alt="Title Promo"
          />

          <img
            className="flex lg:hidden w-10/12 h-auto items-center"
            src={`/images/theme/independence-day-v2/cashback-n-ongkir-promo.png`}
            alt="Cashback Promo"
          />

          <div className="w-[95%] lg:w-full flex items-center justify-center font-nunito-sans px-2">
            <Link
              to={"/link-promo/shopee"}
              className="relative flex items-center w-80 p-2 space-x-5 text-sm sm:text-lg font-bold bg-white hover:bg-slate-100 rounded-full z-[10] shadow-xl border-[3px] border-rose-800 transition-all duration-150"
            >
              <div className="flex p-2 justify-center items-center h-9 w-9 sm:h-11 sm:w-11 rounded-full bg-[#F65421]">
                <img
                  src="/images/icon-marketplace/shopee.svg"
                  alt="Store"
                  className="w-auto h-full object-contain"
                />
              </div>

              <p className="text-[#D31C1C]">DHC Store di Shopee</p>
            </Link>
          </div>

          <img
            className="w-full h-auto lg:w-auto lg:h-52"
            src={`/images/theme/independence-day-v2/products-promo.png`}
            alt="Title Promo"
          />

          <img
            className="hidden lg:flex w-full lg:w-9/12 h-auto absolute absolute-x-center top-0 -z-10"
            src={`/images/theme/independence-day-v2/confeti-promo.svg`}
            alt="Confeti"
          />

          <div className="hidden lg:flex absolute bottom-[16%] -left-6">
            <Lottie
              options={optionsDiscount}
              height={"auto"}
              width={220}
              speed={speed?.lottie1}
            />
          </div>

          <div className="hidden lg:flex space-x-1 absolute bottom-[16%] -right-6">
            <div>
              <Lottie
                options={optionsBaloonFreeOngkir}
                height={"auto"}
                width={150}
                speed={speed?.lottie2}
              />
            </div>

            <div>
              <Lottie
                options={optionsBaloonXtraCashback}
                height={"auto"}
                width={150}
                speed={speed?.lottie3}
              />
            </div>
          </div>

          <div className="hidden lg:flex absolute bottom-0 right-10 px-2 pb-2 pt-1 bg-zinc-300 rounded-t-lg text-xs text-rose-800 font-black font-nunito-sans italic">
            1 - 31 Agustus 2024
          </div>
        </div>
      </motion.div> */}
    </motion.div>
  );
}

export default PromoModal;
