import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet-async";

import Layout from "../components/Layout";
import AboutDHC from "../section/AboutDHC";
import ContactUs from "../section/ContactUs";
import FAQ from "../section/FAQ";
import FindBrands from "../section/FindBrands";
import Hero from "../section/Hero";
import ProductAdvantages from "../section/ProductAdvantages";
import ProductList from "../section/ProductList";
import SubscribeModal from "../components/SubscribeModal";
import PartnerModal from "../components/PartnerModal";
import Testimonial from "../section/Testimonial";
import ReviewMarketplace from "../section/ReviewMarketplace";
import PromoModal from "../components/independece-day-theme/PromoModal";
import Lottie from "react-lottie";
import ghost from "../components/lotties/ghost.json";
// import { screenLaptopXs } from "../utils/Breakpoints";
// import { FaWhatsapp } from "react-icons/fa";

function Home() {
  let tomorrow = new Date();
  tomorrow.setDate(new Date().getDate() + 1);
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(["promo_halloween"]);
  // State for theme
  const [showPromo, setShowPromo] = useState(
    cookies.promo_halloween || { show: true }
  );
  // const [rotation, setRotation] = useState(0);

  const optionsGhost = {
    loop: true,
    animationData: ghost,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // clean cookies after theme event
  // useEffect(() => {
  //   if (
  //     cookies?.promo_valentine ||
  //     cookies?.promo_ramadhan ||
  //     cookies?.promo_kartini ||
  //     cookies?.promo_independence_day
  //   ) {
  //     removeCookie("promo_valentine", { path: "/" });
  //     removeCookie("promo_ramadhan", { path: "/" });
  //     removeCookie("promo_kartini", { path: "/" });
  //     removeCookie("promo_independence_day", { path: "/" });
  //   }
  // }, [cookies, removeCookie]);

  // Halloween Theme
  // useEffect(() => {
  //   // Fungsi untuk menggerakkan dan memutar SVG
  //   const interval = setInterval(() => {
  //     setRotation(rotation === 0 ? [2, 10, -10, 0] : 0); // Memutar 45 derajat sebelumnya
  //   }, 2000); // Setiap 3 detik

  //   return () => clearInterval(interval); // Membersihkan interval saat komponen dibongkar
  // }, [rotation]);

  // Christmas Theme
  // useEffect(() => {
  //   // Fungsi untuk menggerakkan dan memutar SVG
  //   const interval = setInterval(() => {
  //     setRotation(rotation === 0 ? [2, 4, -4, 0] : 0); // Memutar 45 derajat sebelumnya
  //   }, 2000); // Setiap 3 detik

  //   return () => clearInterval(interval); // Membersihkan interval saat komponen dibongkar
  // }, [rotation]);

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://dhcsupplement.co.id/" />
        <title>Home - DHC Supplement</title>
        <meta
          name="description"
          content="DHC Supplement Made in Japan. Loved Worldwide produk berkualitas tinggi dan inovatif dari jepang yang dicintai di seluruh penjuru dunia."
        />
        <meta
          name="keywords"
          content="suplemen, supplement, vitamin, multivitamin, obat diet, folic acid, ibu dan anak, kalsium, obat tulang, collagen, kolagen"
        />
        <meta property="og:title" content="Home - DHC Supplement" />
        <meta
          property="og:description"
          content="DHC Supplement Made in Japan. Loved Worldwide produk berkualitas tinggi dan inovatif dari jepang yang dicintai di seluruh penjuru dunia."
        />
        <meta property="og:url" content="https://dhcsupplement.co.id/" />
      </Helmet>

      {/* Independence Day Theme */}
      {/* <PromoModal
        show={showPromo?.show}
        setShow={setShowPromo}
        toggleModal={() => {
          setCookie(
            "promo_independence_day",
            { show: !showPromo?.show },
            { path: "/", expires: tomorrow }
          );
          setShowPromo({ show: !showPromo?.show });
        }}
      /> */}

      {/* Halloween Theme */}
      <PromoModal
        show={showPromo?.show}
        setShow={setShowPromo}
        toggleModal={() => {
          setCookie(
            "promo_halloween",
            { show: !showPromo?.show },
            { path: "/", expires: tomorrow }
          );
          setShowPromo({ show: !showPromo?.show });
        }}
      />

      {/* Christmas Theme */}
      {/* <PromoModal
        show={showPromo?.show}
        setShow={setShowPromo}
        toggleModal={() => {
          setCookie(
            "promo_christmas",
            { show: !showPromo?.show },
            { path: "/", expires: tomorrow }
          );
          setShowPromo({ show: !showPromo?.show });
        }}
      /> */}

      {/* Chinese New Year Theme */}
      {/* <PromoModal
        show={showPromo?.show}
        setShow={setShowPromo}
        toggleModal={() => {
          setCookie(
            "promo_chinese_ny",
            { show: !showPromo?.show },
            { path: "/", expires: tomorrow }
          );
          setShowPromo({ show: !showPromo?.show });
        }}
      /> */}

      {/* Valentine Theme */}
      {/* <PromoModal
        show={showPromo?.show}
        setShow={setShowPromo}
        toggleModal={() => {
          setCookie(
            "promo_valentine",
            { show: !showPromo?.show },
            { path: "/", expires: tomorrow }
          );
          setShowPromo({ show: !showPromo?.show });
        }}
      /> */}

      {/* Ramadhan Day Theme */}
      {/* <PromoModal
        show={showPromo?.show}
        setShow={setShowPromo}
        toggleModal={() => {
          setCookie(
            "promo_ramadhan",
            { show: !showPromo?.show },
            { path: "/", expires: tomorrow }
          );
          setShowPromo({ show: !showPromo?.show });
        }}
      /> */}

      {/* Kartini Day Theme */}
      {/* <PromoModal
        show={showPromo?.show}
        setShow={setShowPromo}
        toggleModal={() => {
          setCookie(
            "promo_kartini",
            { show: !showPromo?.show },
            { path: "/", expires: tomorrow }
          );
          setShowPromo({ show: !showPromo?.show });
        }}
      /> */}

      <SubscribeModal />
      <PartnerModal />
      <Layout activeNav="home">
        <div className="relative layout-gap">
          {/* Christmas Theme */}
          {/* <div className="w-full flex absolute top-[45vh] left-0 z-[5000]">
            <motion.img
              src="/images/theme/christmas/flying-santa.png" // Ganti dengan URL atau path gambar Santa
              alt="Santa"
              style={{
                width: window.innerWidth >= screenLaptopXs ? "15vw" : "40vw", // Sesuaikan ukuran gambar
                height: "auto",
                position: "absolute",
              }}
              initial={{
                x: window.innerWidth >= screenLaptopXs ? "-15vw" : "-40vw",
              }}
              animate={{
                x: "100vw", // Pindahkan gambar dari kiri ke kanan sejauh lebar layar
              }}
              transition={{
                delay: 3,
                duration: 3.5, // Durasi animasi dalam detik
                ease: "linear", // Jenis easing
              }}
            />
          </div> */}

          {/* <button className="fixed w-11 h-11 bottom-8 right-8 z-10 flex items-center justify-center bg-white rounded-full shadow-card shadow-card-hover transition-all duration-150">
            <FaWhatsapp className="w-8 h-8 text-green-500" />
          </button> */}

          {/* Independence Day Theme */}
          {/* <button
            onClick={() => {
              setCookie(
                "promo_independence_day",
                { show: !showPromo?.show },
                {
                  path: "/",
                  expires: tomorrow,
                }
              );
              setShowPromo({ show: !showPromo?.show });
            }}
            className="fixed w-16 sm:w-20 md:w-20 lg:w-24 xl:w-28 2xl:w-32 bottom-4 right-2 lg:bottom-5 lg:right-3 xl:bottom-6 xl:right-4 5xl:bottom-7 5xl:right-5 z-10 flex items-center justify-center transition-all duration-300 hover:scale-[1.15]"
          >
            <IndependenceDay79 className="w-full h-auto" />
          </button> */}

          {/* Halloween Theme */}
          <button
            onClick={() => {
              setCookie(
                "promo_halloween",
                { show: !showPromo?.show },
                {
                  path: "/",
                  expires: tomorrow,
                }
              );
              setShowPromo({ show: !showPromo?.show });
            }}
            className="fixed w-20 sm:w-24 md:w-28 lg:w-28 xl:w-28 2xl:w-32 5xl:w-40 bottom-4 right-2 lg:bottom-5 lg:right-3 xl:bottom-6 xl:right-4 5xl:bottom-7 5xl:right-5 z-[10000] flex items-center justify-center transition-all duration-300 hover:scale-[1.15]"
          >
            <Lottie
              options={optionsGhost}
              height={"auto"}
              width={120}
              speed={1}
              isClickToPauseDisabled={true}
            />
          </button>

          {/* Christmas Theme */}
          {/* <button
            onClick={() => {
              setCookie(
                "promo_christmas",
                { show: !showPromo?.show },
                {
                  path: "/",
                  expires: tomorrow,
                }
              );
              setShowPromo({ show: !showPromo?.show });
            }}
            className="fixed w-20 sm:w-24 md:w-28 lg:w-28 xl:w-28 2xl:w-32 5xl:w-40 bottom-4 right-2 lg:bottom-5 lg:right-3 xl:bottom-6 xl:right-4 5xl:bottom-7 5xl:right-5 z-[10000] flex items-center justify-center transition-all duration-300 hover:scale-[1.15]"
          >
            <motion.img
              className="w-full h-auto"
              src={"/images/theme/christmas/snowman.svg"}
              alt="Pumpkin"
              initial={{ rotate: 0 }} // Properti awal animasi
              animate={{
                rotate: rotation,
                transition: { duration: 1, ease: "easeInOut" },
              }} // Properti animasi yang akan diperbarui
            />
          </button> */}

          {/* Chinese New Year Theme */}
          {/* <button
            onClick={() => {
              setCookie(
                "promo_chinese_ny",
                { show: !showPromo?.show },
                {
                  path: "/",
                  expires: tomorrow,
                }
              );
              setShowPromo({ show: !showPromo?.show });
            }}
            className="fixed w-16 sm:w-20 md:w-24 lg:w-24 xl:w-24 2xl:w-28 5xl:w-36 bottom-4 right-2 lg:bottom-5 lg:right-4 xl:bottom-6 xl:right-5 5xl:bottom-7 5xl:right-5 z-[10000] flex items-center justify-center transition-all duration-300 hover:scale-[1.15]"
          >
            <Dragon className="w-full h-auto" />
          </button> */}

          {/* Valentine Day Theme */}
          {/* <button
            onClick={() => {
              setCookie(
                "promo_valentine",
                { show: !showPromo?.show },
                {
                  path: "/",
                  expires: tomorrow,
                }
              );
              setShowPromo({ show: !showPromo?.show });
            }}
            className="fixed w-16 sm:w-20 md:w-24 lg:w-24 xl:w-24 2xl:w-28 5xl:w-36 bottom-4 right-2 lg:bottom-5 lg:right-4 xl:bottom-6 xl:right-5 5xl:bottom-7 5xl:right-5 z-[10000] flex items-center justify-center transition-all duration-300 hover:scale-[1.15]"
          >
            <Cupid className="w-full h-auto" />
          </button> */}

          {/* Ramadhan Day Theme */}
          {/* <button
            onClick={() => {
              setCookie(
                "promo_ramadhan",
                { show: !showPromo?.show },
                {
                  path: "/",
                  expires: tomorrow,
                }
              );
              setShowPromo({ show: !showPromo?.show });
            }}
            className="fixed w-16 sm:w-20 md:w-24 lg:w-24 xl:w-24 2xl:w-28 5xl:w-36 bottom-4 right-2 lg:bottom-5 lg:right-4 xl:bottom-6 xl:right-5 5xl:bottom-7 5xl:right-5 z-[10000] flex items-center justify-center transition-all duration-300 hover:scale-[1.15]"
          >
            <img
              src="/images/universal/mosque-n-moon.svg"
              alt="Ramadan"
              className="w-full h-auto"
            />
          </button> */}

          {/* Kartini Day Theme */}
          {/* <button
            onClick={() => {
              setCookie(
                "promo_kartini",
                { show: !showPromo?.show },
                {
                  path: "/",
                  expires: tomorrow,
                }
              );
              setShowPromo({ show: !showPromo?.show });
            }}
            className="fixed w-16 sm:w-20 md:w-24 lg:w-24 xl:w-24 2xl:w-28 5xl:w-36 bottom-4 right-2 lg:bottom-5 lg:right-4 xl:bottom-6 xl:right-5 5xl:bottom-7 5xl:right-5 z-[10000] flex items-center justify-center transition-all duration-300 hover:scale-[1.15]"
          >
            <img
              src="/images/theme/kartini/kartini.svg"
              alt="Kartini"
              className="w-full h-auto"
            />
          </button> */}

          <Hero />
          <AboutDHC />
          <ProductAdvantages />
          <ProductList />
          <FindBrands />
          <Testimonial />
          <ReviewMarketplace />
          <FAQ />
          <ContactUs />
        </div>
      </Layout>
    </>
  );
}

export default Home;
